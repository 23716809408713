<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຜະລິດນ້ຳຂອງສາງໂຮງງານ</h2></v-col>
      <!--      <v-col class="al-end">-->
      <!--        <v-btn-->
      <!--          color="primary"-->
      <!--          @click="exportData()"-->
      <!--          :loading="exportLoading"-->
      <!--          :disabled="exportLoading"-->
      <!--          >Export</v-btn-->
      <!--        ></v-col-->
      <!--      >-->
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row>
      <v-col cols>
        <v-menu
            v-model="start_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="start_date"
                label="ເລີ່ມວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="start_date"
              @input="fetchData()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols>
        <v-menu
            v-model="end_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="end_date"
                label="ຫາວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="end_date"
              @input="fetchData()"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="mb-1 mt-0">
      <v-col>
        <h3 class="success--text">
          ຕຸກນ້ຳທັງໝົດ: {{ Intl.NumberFormat().format(sumTotalBalance) }} ຕຸກ
        </h3>
      </v-col>
      <v-col>
        <h3 class="error--text">
          ຕຸກນ້ຳເຂົ້າສາງ:
          {{ Intl.NumberFormat().format(sumTotalImportBottle) }} ຕຸກ
        </h3>
      </v-col>

      <v-col>
        <h3 class="primary--text">
          ຕຸກນ້ຳອອກສາງ:
          {{ Intl.NumberFormat().format(sumTotalExportBottle) }}
        </h3>
      </v-col>

      <v-col>
        <h3 class="info--text">
          ຍອດຍົກມາ:
          {{ Intl.NumberFormat().format(sumTotalRemainingYesterday) }}
        </h3>
      </v-col>
    </v-row>
    <!-- <hr /> -->
    <v-data-table
        :headers="headers"
        :items="warehouseBottle"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="showDetail(item.id)"
        >mdi-eye
        </v-icon
        >
      </template>
    </v-data-table>
    <br/>
    <!--    <template>-->
    <!--      <Pagination-->
    <!--        v-if="pagination.last_page > 1"-->
    <!--        :pagination="pagination"-->
    <!--        :offset="offset"-->
    <!--        @paginate="fetchData()"-->
    <!--      ></Pagination>-->
    <!--    </template>-->

    <v-dialog
        v-model="modalCenter"
        persistent
        width="900px"
        max-with="1200px"
        loading
    >
      <template @close="close">
        <v-card>
          <v-card-title>
            <h4>ລາຍລະອຽດຕຸກນ້ຳ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols>
                  <p class="invoice">ສາງໂຮງງານ: {{ listWarehouse.name }} ເບີໂທ: {{ listWarehouse.phone }}</p>
                </v-col>
              </v-row>
              <br/>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">ສົ່ງໃຫ້ລູກຄ້າ: {{ exportTo.customer }}</p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">ສົ່ງໃຫ້ລົດ: {{ exportTo.truck }}</p>
                </v-col>
              </v-row>
              <br>
              <v-row>
                <v-col cols>
                  <v-select
                      v-model="selectedStore"
                      :items="store_Types"
                      label="ປະເພດ"
                      item-text="name"
                      item-value="value"
                      dense
                      outlined
                  ></v-select>
                </v-col>
              </v-row>

              <v-data-table
                  :headers="detailHeader"
                  :items="warehouseBottleDetail"
                  :disable-pagination="true"
                  class="elevation-1"
                  hide-default-footer
                  :loading="detailLoading"
                  :disabled="detailLoading"
                  loading-text="Loading... Please wait"
              >
              </v-data-table>
              <br/>
            </v-container>
            <br/>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModaImport()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

  </div>
</template>

<script>
// import Pagination from "@/plugins/pagination/pagination";
export default {
  components: {
    // Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      start_menu: false,
      end_menu: false,

      loading: false,
      exportLoading: false,
      TableLoading: false,
      detailLoading: false,

      modalCenter: false,

      warehouseBottle: [],
      //Sum Variable
      sumTotalBalance: 0,
      sumTotalImportBottle: 0,
      sumTotalExportBottle: 0,
      sumTotalRemainingYesterday: 0,
      warehouseId: "",

      //Pagination
      offset: 12,
      pagination: {},
      per_page: 15,

      //Payment Type
      selectedStore: "import",
      store_Types: [
        {
          value: "import",
          name: "ຮັບເຂົ້າ",
        },
        {
          value: "export",
          name: "ສົ່ງອອກ",
        },
      ],

      headers: [
        {
          text: "ສາງໂຮງງານ",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "ຍອດຍັງເຫຼືອ", value: "sumTotalBalance", align: "center"},
        {text: "ເຂົ້າສາງ", value: "sumTotalImportBottles", align: "center", sortable: false},
        {text: "ສົ່ງອອກ", value: "sumTotalExportBottles", align: "center", sortable: false},
        {text: "ຍອດຍົກມາ", value: "remainingYesterday", align: "center", sortable: false},
        {text: "ສົ່ງອອກໃຫ້ລູກຄ້າ", value: "countExportBottleToCustomer", align: "center", sortable: false},
        {text: "ສົ່ງອອກໃຫ້ລົດ", value: "countExportBottleToTruck", align: "center"},
        {text: "", value: "action"},
      ],
      exportTo: {},
      listWarehouse: {},
      warehouseBottleDetail: [],
      detailHeader: [
        {
          text: "ຜະລິດຕະພັນ",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "ຍອດຍັງເຫຼືອ", value: "total", align: "center"},
        {text: "", value: "action"},
      ],
    };
  },
  metaInfo() {
    return {
      title: `ລາຍງານຍອດຕຸກນ້ຳໂຮງງານ`,
    }
  },
  methods: {
    fetchData() {
      // (this.pagination.current_page);
      this.TableLoading = true;
      this.$axios
          .post("report/warehouse/import/and/export/bottles", {
            startDate: this.start_date,
            endDate: this.end_date,
          })
          .then((res) => {
            this.warehouseBottle = res.data.listWareHouseBottles;
            this.sumTotalBalance = res.data.sumTotalBalance;
            this.sumTotalExportBottle = res.data.sumTotalExportBottles;
            this.sumTotalImportBottle = res.data.sumTotalImportBottles;
            this.sumTotalRemainingYesterday = res.data.sumTotalRemainingYesterday;
            this.start_menu = false;
            this.end_menu = false;
            this.TableLoading = false;
          })
          .catch(() => {
            this.TableLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    fetchWarehouseDetail() {
      // (this.pagination.current_page);
      this.detailLoading = true;
      this.$axios
          .post("report/warehouse/import/and/export/bottles/detail", {
            startDate: this.start_date,
            endDate: this.end_date,
            warehouse_id: this.warehouseId,
            store_type: this.selectedStore,
          })
          .then((res) => {
            this.warehouseBottleDetail = res.data.listBottles.data;
            this.exportTo = res.data.exportBottle;
            this.listWarehouse = res.data.listWarehouseBottles;
            this.start_menu = false;
            this.end_menu = false;
            this.detailLoading = false;
          })
          .catch(() => {
            this.detailLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    closeModaImport() {
      this.modalCenter = false;
    },
    showDetail(value) {
      this.modalCenter = true;
      this.warehouseId = value;
      this.fetchWarehouseDetail();
    },

    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$axios
          .post(
              "export/factory/import/and/export/bottles",
              {
                startDate: this.start_date,
                endDate: this.end_date,
                store_type: this.selectedStore,
              },
              {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "ລາຍງານຍອດຕຸກນ້ຳ " + this.start_date + "_" + this.end_date + ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },
  watch: {
    selectedStore: function () {
      this.fetchWarehouseDetail();
    }
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}

.invoice {
  font-size: 16px;
  color: #000000;
}

.primary-color {
  color: #00c1d2;
}
</style>
