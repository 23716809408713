// import truckbottle  from "@/mixins/reportbottle/truckbottle"
export default {
  // mixins: [truckbottle],
    data() {
      return {
        //modal
        modalInboundBranchDetail: false,
        modalInboundBranch: false,

        inboundbranches: [],
        inboundbranchesdetail:[],
        inboundBranchHeader: [
            {
              text: "ຊື່",
              align: "start",
              sortable: false,
              value: "name",
            },
            { text: "ຈຳນວນຕຸກ", value: "sumTotalBottle" },
            // { text: "ວັນທີ", value: "created_at" },
            { text: "", value: "actions" },
          ],
          inboundBranchHeadeDetail: [
              {
                text: "Barcode",
                align: "start",
                sortable: false,
                value: "barcode",
              },
              { text: "ວັນທີ", value: "import_date" },
              { text: "", value: "actions" },
            ],
      };
    },
    methods: {
    openModalInboundBranch(id) {
        this.loading = true;
        this.truckId = id;
        this.$axios
          .post("report/driver/export/bottle/from/branch", {
            truck_id: this.truckId,
            startDate: this.start_date,
            endDate: this.end_date,
          })
          .then((res) => {
            setTimeout(() => {
              this.inboundbranches = res.data.branches;
              if (this.inboundbranches.length > 0) {
                this.modalInboundBranch = true;
              }
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
      },
      closeModalInboundBranch() {
        this.inboundbranches = [];
        this.modalInboundBranch = false;
      },
      openModalInboundBranchDetail(value) {
        this.loading = true;
        this.$axios
          .post("report/driver/export/bottle/detail/from/branch", {
            truck_id: this.truckId,
            import_from_id:value,
            startDate: this.start_date,
            endDate: this.end_date,
          })
          .then((res) => {
            setTimeout(() => {
              this.inboundbranchesdetail = res.data.driverProducts;
              if (this.inboundbranchesdetail.length > 0) {
                this.modalInboundBranchDetail = true;
      
              }
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
      },
      closeModalInboundBranchDetail() {
        this.inboundbranchesdetail = [];
        this.modalInboundBranchDetail = false;
      },
    },
  };