export default {
      data() {
        return {
          modalBranchToTruckDetail:false,
            modalBranchToTruck:false,
            branchtotruckdetail:[],
            branchtotruck:[],

            branchToTruckHeader: [
            {
              text: "ເລກລົດ",
              align: "start",
              sortable: false,
              value: "license_plate",
            },
            { text: "ຈຳນວນຕຸກ", value: "sumTotalBottle", sortable: false },
            // { text: "ວັນທີ", value: "created_at" },
            { text: "", value: "actions" },
          ],
          branchToTruckHeaderDetail: [
            {
              text: "ລະຫັດຕຸກນ້ຳ",
              align: "start",
              sortable: false,
              value: "barcode",
            },
            { text: "ວັນທີ", value: "import_date" },
          ],
        };
      },
      methods: {
        openModalBranchToTruck(value) {
          this.branchId = value;
          this.loading = true;
          this.$axios
            .post("report/branch/export/bottle/from/truck", {
              branch_id: value,
              startDate: this.start_date,
              endDate: this.end_date,
            })
            .then((res) => {
              setTimeout(() => {
                this.branchtotruck = res.data.trucks;
                (this.branchtotruck)
                if(this.branchtotruck.length > 0){
                    this.modalBranchToTruck =true;
                }
                this.loading = false;
              }, 100);
            })
            .catch(() => {
              this.loading = false;
              // this.viewModalBranch = false;
              this.$store.commit("Toast_State", this.toast_error);
            });
        },
        closeModalBranchToTrucks() {
          ("He")
            this.modalBranchToTruck =false;
        },

        openModalBranchToTruckDetail(value) {
          this.loading = true;
          this.$axios
            .post("report/branch/export/bottle/detail/from/truck", {
              branch_id: this.branchId,
              export_to_id:value,
              startDate: this.start_date,
              endDate: this.end_date,
            })
            .then((res) => {
              setTimeout(() => {
                this.branchtotruckdetail = res.data.branchProducts;
                if(this.branchtotruckdetail.length > 0){
                    this.modalBranchToTruckDetail =true;
                }
                this.loading = false;
              }, 100);
            })
            .catch(() => {
              this.loading = false;
              this.$store.commit("Toast_State", this.toast_error);
            });
        },
        closeModalBranchToTruckDetail() {
            this.modalBranchToTruckDetail =false;
        },
      },
    };