import inboundbranchbottle  from "@/mixins/reportbottle/inboundbranchbottle"
import bottlefromtruck  from "@/mixins/reportbottle/bottlefromtruck"
import bottletotruck  from "@/mixins/reportbottle/bottletotruck"
import bottletocustomer  from "@/mixins/reportbottle/bottletocustomer"
export default {
    mixins: [inboundbranchbottle,bottlefromtruck,bottletotruck,bottletocustomer],
    data() {
      return {
        start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        start_menu: false,
        end_menu: false,
        loading: false,
        TableLoading: false,
        //modal
        viewModal: false,
        viewModalTruckExport: false,
        modalFromBranch: false,
        exportLoading: false,
  
        //Detail bottle
        frombranches: [],
        frombranchesdetail:[],
        inboundbranches: [],
        inboundbranchesdetail:[],
  
        truckBottle: [],
        bottleTruckDetail: [],
        bottleExportTruckDetail: [],
        truckDetail: {},
        exportBottles: {},
        importBottles: {},
        customerDetail: {},
        createdByDetail: {},
        //Sum Bottle
        sumTotalBalance: 0,
        sumTotalExportBottles: 0,
        sumTotalExportToBranch: 0,
        sumTotalExportToCustomer: 0,
        sumTotalExportToFactory: 0,
        sumTotalExportToTruck: 0,
        sumTotalImportBottles: 0,
        sumTotalImportFromBranch: 0,
        sumTotalImportFromFactory: 0,
        sumTotalImportFromTruck: 0,
        sumTotalImportFromWareHouse: 0,
        sumTotalExportToWareHouse: 0,
  
        truckId: "",
        //Pagination
        offset: 8,
        pagination: {},
        per_page: 12,
        headers: [
          {
            text: "ທະບຽນລົດ",
            align: "start",
            sortable: false,
            value: "license_plate",
          },
          { text: "ຍອດຍົກມາ", align: "center", value: "remainingYesterday" },
          { text: "ຍອດເຫຼືອທັງໝົດ", align: "center", value: "sumTotalBalance" },
          { text: "ຮັບເຂົ້າທັງໝົດ", align: "center", value: "sumTotalImportBottles" },
          { text: "ສົ່ງອອກທັງໝົດ", align: "center", value: "sumTotalExportBottles" },
          // {
          //   text: "ຮັບຈາກໂຮງງານ",
          //   align: "center",
          //   value: "countImportBottleFromFactory",
          // },
          // {
          //   text: "ຮັບຈາກສາຂາ",
          //   align: "center",
          //   value: "countImportBottleFromBranch",
          // },
  
          // {
          //   text: "ເຂົ້າສາຂາ",
          //   align: "center",
          //   value: "countExportBottleToBranch",
          // },
          // {
          //   text: "ຮັບຈາກລົດ",
          //   align: "center",
          //   value: "countImportBottleFromTruck",
          // },
          // {
          //   text: "ເອົາອອກໃຫ້ລົດ",
          //   align: "center",
          //   value: "countExportBottleToTruck",
          // },
          // {
          //   text: "ກັບຄືນໂຮງງານ",
          //   align: "center",
          //   value: "countExportBottleToFactory",
          // },

          // {
          //   text: "ຂາຍໃຫ້ລູກຄ້າ",
          //   align: "center",
          //   value: "countExportBottleToCustomer",
          // },
          // { text: "ລາຍລະອຽດ", value: "actions", align: "center" },
          // { text: "ວັນທີ", value: "created_at", align: "center" },
        ],
  
        truckHeader: [
          {
            text: "ລະຫັດຕຸກນ້ຳ",
            align: "start",
            sortable: false,
            value: "barcode",
          },
          { text: "ຮັບຈາກ", value: "importFrom.name" },
          { text: "ປະເພດ", value: "import_type" },
          { text: "ຜູ້ຮັບຜິດຊອບ", value: "import_by_user" },
          { text: "ວັນທີ", value: "import_date" },
        ],
        exportTruckHeader: [
          {
            text: "ລະຫັດຕຸກນ້ຳ",
            align: "start",
            sortable: false,
            value: "barcode",
          },
          { text: "ສົ່ງອອກໃຫ້ເເກ່", value: "exportTo.name" },
          { text: "ປະເພດ", value: "export_type" },
          { text: "ຜູ້ຮັບຜິດຊອບ", value: "export_by_user" },
          { text: "ວັນທີ", value: "export_date" },
        ],

        branchHeader: [
          {
            text: "ຊື່",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "ຈຳນວນຕຸກ", value: "sumTotalBottle" },
          // { text: "ວັນທີ", value: "created_at" },
          { text: "", value: "actions" },
        ],
        branchHeaderDetail: [
            {
              text: "Barcode",
              align: "start",
              sortable: false,
              value: "barcode",
            },
            { text: "ວັນທີ", value: "import_date" },
            { text: "", value: "actions" },
          ],
        //   ======== Inbound Table
        inboundBranchHeader: [
            {
              text: "ຊື່",
              align: "start",
              sortable: false,
              value: "name",
            },
            { text: "ຈຳນວນຕຸກ", value: "sumTotalBottle" },
            // { text: "ວັນທີ", value: "created_at" },
            { text: "", value: "actions" },
          ],
          inboundBranchHeadeDetail: [
              {
                text: "Barcode",
                align: "start",
                sortable: false,
                value: "barcode",
              },
              { text: "ວັນທີ", value: "import_date" },
              { text: "", value: "actions" },
            ],
      };
    },
    methods: {
      fetchTruckBottle() {
        this.TableLoading = true;
        this.$axios
          .post("report/driver/import/and/export/bottles", {
            startDate: this.start_date,
            endDate: this.end_date,
          })
          .then((res) => {
            setTimeout(() => {
              this.truckBottle = res.data.listTrucks;
              this.sumTotalExportToBranch = res.data.sumTotalExportToBranch;
              this.sumTotalExportBottles = res.data.sumTotalExportBottles;
              this.sumTotalBalance = res.data.sumTotalBalance;
              this.sumTotalExportToCustomer = res.data.sumTotalExportToCustomer;
              this.sumTotalExportToFactory = res.data.sumTotalExportToFactory;
              this.sumTotalExportToTruck = res.data.sumTotalExportToTruck;
              this.sumTotalImportBottles = res.data.sumTotalImportBottles;
              this.sumTotalImportFromBranch = res.data.sumTotalImportFromBranch;
              this.sumTotalImportFromFactory = res.data.sumTotalImportFromFactory;
              this.sumTotalImportFromTruck = res.data.sumTotalImportFromTruck;
              this.sumTotalImportFromWareHouse = res.data.sumTotalImportFromWareHouse;
              this.sumTotalExportToWareHouse = res.data.sumTotalExportToWareHouse;
              this.start_menu = false;
              this.end_menu = false;
              this.TableLoading = false;
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
      },
      openModalViewImportTruck(value) {
        this.viewModal = true;
        this.truckId = value;
        this.truckImportBottleDetail();
      },
      closeModaImport() {
        this.pagination.current_page = "";
        this.viewModal = false;
      },
      truckImportBottleDetail() {
        this.loading = true;
        // this.viewModal = true;
        this.$axios
          .post("report/driver/import/and/export/bottles/detail", {
            truck_id: this.truckId,
            store_type: "import",
            startDate: this.start_date,
            endDate: this.end_date,
            page: this.pagination.current_page,
            per_page: this.per_page,
          })
          .then((res) => {
            setTimeout(() => {
              this.bottleTruckDetail = res.data.listBottles.data;
              this.pagination = res.data.listBottles;
              this.truckDetail = res.data.listTruck;
              this.importBottles = res.data.importBottles;
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
      },
      openModalExportViewTruck(value) {
        this.viewModalTruckExport = true;
        this.truckId = value;
        this.truckExportBottleDetail();
      },
      closeModalExport() {
        this.pagination.current_page = "";
        this.viewModalTruckExport = false;
      },
      truckExportBottleDetail() {
        this.$axios
          .post("report/driver/import/and/export/bottles/detail", {
            truck_id: this.truckId,
            store_type: "export",
            startDate: this.start_date,
            endDate: this.end_date,
            page: this.pagination.current_page,
            per_page: this.per_page,
          })
          .then((res) => {
            setTimeout(() => {
              this.bottleExportTruckDetail = res.data.listBottles.data;
              this.pagination = res.data.listBottles;
              this.truckDetail = res.data.listTruck;
              this.exportBottles = res.data.exportBottles;
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            // this.viewModal = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
  
      },

      openModalFrombranch(id) {
        this.loading = true;
        this.truckId = id;
        this.$axios
          .post("report/driver/import/bottle/from/branch", {
            truck_id: this.truckId,
            startDate: this.start_date,
            endDate: this.end_date,
          })
          .then((res) => {
            setTimeout(() => {
              this.frombranches = res.data.branches;
              if (this.frombranches.length > 0) {
                this.$store.commit("modalView_State", true);
            }
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });

      },
      closeModalFromBranch() {
        this.$store.commit("modalView_State", false);
        this.frombranches =[];
      },
      openModalFrombranchDetail(value) {
        this.loading = true;
        this.$axios
          .post("report/driver/import/bottle/detail/from/branch", {
            truck_id: this.truckId,
            import_from_id:value,
            startDate: this.start_date,
            endDate: this.end_date,
          })
          .then((res) => {
            setTimeout(() => {
              this.frombranchesdetail = res.data.driverProducts;
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
        if (this.frombranchesdetail.length > 0) {
          this.$store.commit("modalView_State2", true);
        }
      },
      closeModalFromBranchDetail() {
        this.$store.commit("modalView_State2", false);
        this.frombranchesdetail = [];
      },

      //Export excel
      exportData() {
        this.exportLoading = true;
        this.$axios
            .post(
                "export/driver/import/and/export/bottles",
                {
                  startDate: this.start_date,
                  endDate: this.end_date,
                },
                { responseType: "blob" }
            )
            .then((res) => {
              setTimeout(() => {
                const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                const fileLink = document.createElement("a");
                fileLink.href = fileUrl;
                fileLink.setAttribute(
                    "download",
                    "ລາຍງານຍອດຕຸກນ້ຳລົດຂົນສົ່ງ "+this.start_date +"_"+ this.end_date + ".xlsx"
                );
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                this.exportLoading = false;
              }, 100);
            })
            .catch(() => {
              this.exportLoading = false;
              this.$store.commit("Toast_State", this.toast_error);
            });
      },

    },
    created() {
      this.fetchTruckBottle();
    },
  };