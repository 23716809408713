var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',[_c('h2',[_vm._v("ສະຫຼຸບຍອດຜະລິດນ້ຳຂອງສາງໂຮງງານ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ເລີ່ມວັນທີ","readonly":"","outlined":"","dense":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.start_menu),callback:function ($$v) {_vm.start_menu=$$v},expression:"start_menu"}},[_c('v-date-picker',{on:{"input":function($event){return _vm.fetchData()}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-col',{attrs:{"cols":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ຫາວັນທີ","readonly":"","outlined":"","dense":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.end_menu),callback:function ($$v) {_vm.end_menu=$$v},expression:"end_menu"}},[_c('v-date-picker',{on:{"input":function($event){return _vm.fetchData()}},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1)],1),_c('v-row',{staticClass:"mb-1 mt-0"},[_c('v-col',[_c('h3',{staticClass:"success--text"},[_vm._v(" ຕຸກນ້ຳທັງໝົດ: "+_vm._s(Intl.NumberFormat().format(_vm.sumTotalBalance))+" ຕຸກ ")])]),_c('v-col',[_c('h3',{staticClass:"error--text"},[_vm._v(" ຕຸກນ້ຳເຂົ້າສາງ: "+_vm._s(Intl.NumberFormat().format(_vm.sumTotalImportBottle))+" ຕຸກ ")])]),_c('v-col',[_c('h3',{staticClass:"primary--text"},[_vm._v(" ຕຸກນ້ຳອອກສາງ: "+_vm._s(Intl.NumberFormat().format(_vm.sumTotalExportBottle))+" ")])]),_c('v-col',[_c('h3',{staticClass:"info--text"},[_vm._v(" ຍອດຍົກມາ: "+_vm._s(Intl.NumberFormat().format(_vm.sumTotalRemainingYesterday))+" ")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.warehouseBottle,"disable-pagination":true,"hide-default-footer":"","loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showDetail(item.id)}}},[_vm._v("mdi-eye ")])]}}])}),_c('br'),_c('v-dialog',{attrs:{"persistent":"","width":"900px","max-with":"1200px","loading":""},model:{value:(_vm.modalCenter),callback:function ($$v) {_vm.modalCenter=$$v},expression:"modalCenter"}},[[_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v("ລາຍລະອຽດຕຸກນ້ຳ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":""}},[_c('p',{staticClass:"invoice"},[_vm._v("ສາງໂຮງງານ: "+_vm._s(_vm.listWarehouse.name)+" ເບີໂທ: "+_vm._s(_vm.listWarehouse.phone))])])],1),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"invoice"},[_vm._v("ສົ່ງໃຫ້ລູກຄ້າ: "+_vm._s(_vm.exportTo.customer))])]),_c('v-col',{attrs:{"cols":"6"}},[_c('p',{staticClass:"invoice"},[_vm._v("ສົ່ງໃຫ້ລົດ: "+_vm._s(_vm.exportTo.truck))])])],1),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":""}},[_c('v-select',{attrs:{"items":_vm.store_Types,"label":"ປະເພດ","item-text":"name","item-value":"value","dense":"","outlined":""},model:{value:(_vm.selectedStore),callback:function ($$v) {_vm.selectedStore=$$v},expression:"selectedStore"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.detailHeader,"items":_vm.warehouseBottleDetail,"disable-pagination":true,"hide-default-footer":"","loading":_vm.detailLoading,"disabled":_vm.detailLoading,"loading-text":"Loading... Please wait"}}),_c('br')],1),_c('br'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":function($event){return _vm.closeModaImport()}}},[_vm._v(" Close ")])],1)],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }