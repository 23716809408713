export default {
      data() {
        return {
            modalTruckToBranchDetail:false,
            modaltruckToBranch:false,
            truckproducts:[],
            truckList:[],

            truckToBranchHeader: [
            {
              text: "ເລກລົດ",
              align: "start",
              sortable: false,
              value: "license_plate",
            },
            { text: "ຈຳນວນຕຸກ", value: "sumTotalBottle", sortable: false },
            // { text: "ວັນທີ", value: "created_at" },
            { text: "", value: "actions" },
          ],
          truckToBranchHeaderDetail: [
            {
              text: "ລະຫັດຕຸກນ້ຳ",
              align: "start",
              sortable: false,
              value: "barcode",
            },
            { text: "ວັນທີ", value: "import_date" },
          ],
        };
      },
      methods: {
        openModalTruckTobranchBranch(value) {
          this.branchId = value;
          this.loading = true;
          this.$axios
            .post("report/branch/import/bottle/from/truck", {
              branch_id: value,
              startDate: this.start_date,
              endDate: this.end_date,
            })
            .then((res) => {
              setTimeout(() => {
                this.truckList = res.data.trucks;
                if(this.truckList.length > 0){
                    this.modaltruckToBranch =true;
                }
                this.loading = false;
              }, 100);
            })
            .catch(() => {
              this.loading = false;
              // this.viewModalBranch = false;
              this.$store.commit("Toast_State", this.toast_error);
            });
        },
        closeModalTruckTobranch() {
            this.modaltruckToBranch =false;
        },
        // openModalTruckToBranchDetail(value) {
        //   this.viewModalBranchExport = true;
        //   this.branchId = value;
        //   this.exportBranchBotleDetail();
        // },

        openModalTruckToBranchDetail(value) {
          this.loading = true;
          this.$axios
            .post("report/branch/import/bottle/detail/from/truck", {
              branch_id: this.branchId,
              import_from_id:value,
              startDate: this.start_date,
              endDate: this.end_date,
            })
            .then((res) => {
              setTimeout(() => {
                this.truckproducts = res.data.branchProducts;
                if(this.truckproducts.length > 0){
                    this.modalTruckToBranchDetail =true;
                }
                this.loading = false;
              }, 100);
            })
            .catch(() => {
              this.loading = false;
              this.$store.commit("Toast_State", this.toast_error);
            });
        },
        closeModalTruckToBranchDetail() {
            this.modalTruckToBranchDetail =false;
        },
      },
    };