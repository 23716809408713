<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຜະລິດນ້ຳຂອງໂຮງງານ</h2></v-col>
      <v-col class="al-end">
        <v-btn
          color="primary"
          @click="exportData()"
          :loading="exportLoading"
          :disabled="exportLoading"
          >Export</v-btn
        ></v-col
      >
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row>
      <v-col cols="4">
        <v-menu
          v-model="start_menu"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date"
              label="ເລີ່ມວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu
          v-model="end_menu"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date"
              label="ຫາວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="selectedStore"
          :items="store_Types"
          label="ປະເພດ"
          item-text="name"
          item-value="value"
          dense
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex">
        <v-autocomplete
            v-model="selectedFactory"
            :items="factories"
            label="Factory"
            item-text="name"
            item-value="id"
            dense
            outlined
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-btn
            color="primary" @click="fetchData">Filter</v-btn>
      </v-col>
    </v-row>
    <v-row class="mb-1 mt-0">
      <v-col>
        <h3 class="success--text">
          ຜະລິດທັງໝົດ: {{ Intl.NumberFormat().format(sumTotalImportBottle) }} ຕຸກ
        </h3>
      </v-col>
      <v-col>
        <h3 class="error--text">
          ສົ່ງອອກທັງໝົດ:
          {{ Intl.NumberFormat().format(sumTotalExportBottle) }} ຕຸກ
        </h3>
      </v-col>
      <!--
      <v-col>
        <h3 class="primary--text">
          ຕຸກນ້ຳຢູ່ໂຮງງານ:
          {{ Intl.NumberFormat().format(sumTotalImportBottle) }}
        </h3>
      </v-col>
     
      <v-col>
        <h3 class="info--text">
          ຕຸກເປົ່າເຂົ້າໂຮງງານ:
          {{ Intl.NumberFormat().format(sumTotalImportBottle) }}
        </h3>
      </v-col>
      -->
    </v-row>
    <!-- <hr /> -->
    <v-data-table
      :headers="headers"
      :items="factoryBottle"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
    </v-data-table>
    <br />
    <template>
      <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchData()"
      ></Pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      start_menu: false,
      end_menu: false,

      loading: false,
      exportLoading: false,
      TableLoading: false,
      factoryBottle: [],

      selectedFactory: "",
      factories: [],
      //Sum Variable
      sumTotalExportBottle: 0,
      sumTotalImportBottle: 0,

      //Pagination
      offset: 12,
      pagination: {},
      per_page: 25,

      //Payment Type
      selectedStore: "import",
      store_Types: [
        {
          value: "import",
          name: "ຜະລິດ",
        },
        {
          value: "export",
          name: "ສົ່ງອອກ",
        },
      ],

      headers: [
        {
          text: "ບາໂຄດ",
          align: "start",
          sortable: false,
          value: "barcode",
        },
        { text: " ວັນທີຜະລິດ", value: "import_date" },
        { text: "ຜູ້ຜະລິດ", value: "import_by_user", sortable: false },
        { text: "ສົ່ງອອກໃຫ້ເເກ່", value: "exportTo.name", sortable: false },
        { text: "ປະເພດ", value: "export_type", sortable: false },
        { text: "ຜູ້ສົ່ງອອກ", value: "export_by_user", sortable: false },
        { text: "ວັນທີສົ່ງອອກ", value: "export_date" },
      ],
    };
  },
  metaInfo() {
    return {
      title: `ລາຍງານຍອດຕຸກນ້ຳໂຮງງານ`,
    }
  },
  methods: {
    fetchFactory() {
      this.$admin.get('list-factories').then(res => {
        this.factories = res.data.data.data;
        this.selectedFactory = res.data.data.data[0].id;
        console.log(this.selectedFactory)
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    fetchData() {
      // (this.pagination.current_page);
      this.TableLoading = true;
      this.$axios
        .post("/report/factory/import/and/export/bottles", {
          startDate: this.start_date,
          endDate: this.end_date,
          store_type: this.selectedStore,
          page: this.pagination.current_page,
          per_page: this.per_page,
          factory_id: this.selectedFactory,

        })
        .then((res) => {
          setTimeout(() => {
            this.factoryBottle = res.data.listProducts.data;
            this.pagination = res.data.listProducts;
            this.sumTotalExportBottle = res.data.sumTotalExportBottle;
            this.sumTotalImportBottle = res.data.sumTotalImportBottle;
            this.start_menu = false;
            this.end_menu = false;
            this.TableLoading = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.end_menu = false;
          this.start_menu = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$axios
        .post(
          "export/factory/import/and/export/bottles",
          {
            startDate: this.start_date,
            endDate: this.end_date,
            store_type: this.selectedStore,
          },
          { responseType: "blob" }
        )
        .then((res) => {
          setTimeout(() => {
            const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute(
              "download",
              "ລາຍງານຍອດຕຸກນ້ຳ "+this.start_date +"_"+ this.end_date + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            this.exportLoading = false;
          }, 100);
        })
        .catch(() => {
          this.exportLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
  },
  watch:{
  },
  created() {
    this.fetchFactory();
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
