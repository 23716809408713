<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#tab-1">ຍອດຕຸກນ້ຳໂຮງງານ</v-tab>
        <v-tab href="#tab-2">ຍອດຕຸກນ້ຳສາງໂຮງງານ</v-tab>
        <v-tab href="#tab-3">ຍອດຕຸກນ້ຳຕາມລົດຂົນສົ່ງ</v-tab>
        <v-tab href="#tab-4">ຍອດຕຸກນ້ຳຕາມສາຂາ</v-tab>
      </v-tabs>
      <!-- <hr /> -->

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text>
              <Sale />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text>
              <SortingCenter />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-3">
          <v-card flat>
            <v-card-text>
              <DeliverySale />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-4">
          <v-card flat>
            <v-card-text> <BranchSale /> </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <!--
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>{{ text }}</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      -->
    </v-card>
  </div>
</template>

<script>
import Sale from "@views/Report/bottle/tabbar/report_all_bottle_tab";
import SortingCenter from "@views/Report/bottle/tabbar/report_sorting_center_bottle_tab";
import BranchSale from "@views/Report/bottle/tabbar/report_branch_bottle_tab";
import DeliverySale from "@views/Report/bottle/tabbar/report_truck_bottle_tab";

export default {
  components: {
    Sale,
    SortingCenter,
    BranchSale,
    DeliverySale,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    if (this.$route.query.tab == "report-all-bottle") {
      this.tab = "tab-1";
    } else if (this.$route.query.tab == "report-sorting-center-bottle") {
      this.tab = "tab-2";
    }
    else if (this.$route.query.tab == "report-truck-bottle") {
      this.tab = "tab-3";
    } else if (this.$route.query.tab == "report-branch-bottle") {
      this.tab = "tab-4";
    }
  },
  watch: {
    tab: function (value) {
      if (value == "tab-1") {
        this.$router
          .push({ name: "Report-Bottle", query: { tab: "report-all-bottle" } })
          .catch(() => {});
      } else if  (value == "tab-2") {
        this.$router
            .push({ name: "Report-Bottle", query: { tab: "report-sorting-center-bottle" } })
            .catch(() => {});
      }
      else if (value == "tab-3") {
        this.$router
          .push({
            name: "Report-Bottle",
            query: { tab: "report-truck-bottle" },
          })
          .catch(() => {});
      } else if (value == "tab-4") {
        this.$router
          .push({
            name: "Report-Bottle",
            query: { tab: "report-branch-bottle" },
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style>
</style>
