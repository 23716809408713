import trucktobranch  from "@/mixins/reportbottle/branchbottle/trucktobranch"
import outboundbranch  from "@/mixins/reportbottle/branchbottle/outboundbranch"
import branchtocustomer  from "@/mixins/reportbottle/branchbottle/branchtocustomer"
export default {
  mixins: [trucktobranch,outboundbranch,branchtocustomer],
    data() {
      return {
        start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        start_menu: false,
        end_menu: false,
        exportLoading: false,
        loading: false,
        TableLoading: false,
        viewModalBranch: false,
        viewModalBranchExport: false,
        branchBottles: [],
        branchBottleDetail: [],
        branchExportBottleDetail: [],
        branchDetail: {},
        exportBottle: {},
        createdByDetail: {},
  
        sumImportBottlesToCustomer: 0,
        sumImportBottlesToTruck: 0,
        sumTotalExportBottles: 0,
        sumTotalImportBottles: 0,
        sumTotalCurrentBottles:0,
        sumExportBottlesToTruck: 0,
        sumImportBottlesFromTruck: 0,
        sumTotalBalance: 0,
  
        branchId: "",
        //Pagination
        offset: 8,
        pagination: {},
        per_page: 12,
        headers: [
          {
            text: "ສາຂາ",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "ຍອດຍົກມາ", align: "center", value: "remainingYesterday" },
          { text: "ຍອດເຫຼືອທັງໝົດ", align: "center", value: "sumTotalBalance" },
          { text: "ຮັບເຂົ້າທັງໝົດ", align: "center", value: "sumTotalImportBottles" },
          { text: "ສົ່ງອອກທັງໝົດ", align: "center", value: "sumTotalExportBottles" },
          // { text: "ຮັບຕຸກນ້ຳຈາກລົດ", align: "center", value: "countImportBottleFromTruck" },
          // {
          //   text: "ຂາຍໃຫ້ລູກຄ້າ",
          //   value: "countExportBottleToCustomer",
          //   align: "center",
          // },
          // { text: "ຕຸກນ້ຳຢູ່ສາຂາ", value: "bonding_fees" },
          // { text: "ຕຸກເປົ່າຢູ່ສາຂາ", value: "subTotal" },
          // { text: "ວັນທີ", value: "created_at" },
        ],
        detailHeader: [
          {
            text: "ບາໂຄດ",
            align: "start",
            sortable: false,
            value: "barcode",
          },
          { text: "ຮັບຈາກລົດຂົນສົ່ງ", value: "importFrom.name", sortable: false },
          { text: "ຜູ້ຮັບຜິດຊອບ", value: "import_by_user", sortable: false },
          { text: "ວັນທີ", value: "import_date" },
        ],
        exportdetailHeader: [
          {
            text: "ບາໂຄດ",
            align: "start",
            sortable: false,
            value: "barcode",
          },
          { text: "ສົ່ງອອກໃຫ້ເເກ່", value: "exportTo.name", sortable: false },
          { text: "ປະເພດ", value: "export_type", sortable: false },
          { text: "ຜູ້ຮັບຜິດຊອບ", value: "export_by_user", sortable: false },
          { text: "ວັນທີ", value: "export_date" },
        ],
      };
    },
    methods: {
      fetchBranchBottle() {
        this.TableLoading = true;
        this.$axios
          .post("report/branch/import/and/export/bottles", {
            startDate: this.start_date,
            endDate: this.end_date,
          })
          .then((res) => {
            setTimeout(() => {
              this.branchBottles = res.data.listBranches;
              this.sumImportBottlesToCustomer =
                res.data.sumImportBottlesToCustomer;
              this.sumImportBottlesToTruck = res.data.sumImportBottlesToTruck;
              this.sumTotalExportBottles = res.data.sumTotalExportBottles;
              this.sumTotalImportBottles = res.data.sumTotalImportBottles;
              this.sumTotalCurrentBottles = res.data.sumTotalBottles;
              this.sumImportBottlesFromTruck = res.data.sumImportBottlesFromTruck;
              this.sumExportBottlesToTruck = res.data.sumExportBottlesToTruck;
              this.sumTotalBalance = res.data.sumTotalBalance;
              (res.data);
              this.start_menu = false;
              this.end_menu = false;
              this.TableLoading = false;
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
      },
      openModalView(value) {
        this.viewModalBranch = true;
        this.branchId = value;
        this.branchBotleDetail();
      },
      closeModalViewBranch() {
        this.viewModalBranch = false;
        this.pagination.current_page = "";
      },
  
      branchBotleDetail() {
        this.loading = true;
        this.$axios
          .post("report/branch/import/and/export/bottles/detail", {
            branch_id: this.branchId,
            startDate: this.start_date,
            endDate: this.end_date,
            store_type: "import",
            page: this.pagination.current_page,
            per_page: this.per_page,
          })
          .then((res) => {
            setTimeout(() => {
              this.branchBottleDetail = res.data.listBottles.data;
              this.branchDetail = res.data.listBranch;
              this.pagination = res.data.listBottles;
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            // this.viewModalBranch = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
      },
      openModalViewExportBranch(value) {
        this.viewModalBranchExport = true;
        this.branchId = value;
        this.exportBranchBotleDetail();
      },
      closeModalExportBranch() {
        this.viewModalBranchExport = false;
        this.pagination.current_page = "";
      },
      exportBranchBotleDetail() {
        this.loading = true;
        this.$axios
          .post("report/branch/import/and/export/bottles/detail", {
            branch_id: this.branchId,
            startDate: this.start_date,
            endDate: this.end_date,
            store_type: "export",
            page: this.pagination.current_page,
            per_page: this.per_page,
          })
          .then((res) => {
            setTimeout(() => {
              this.branchExportBottleDetail = res.data.listBottles.data;
              this.branchDetail = res.data.listBranch;
              this.exportBottle = res.data.exportBottle;
              this.pagination = res.data.listBottles;
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            // this.viewModalBranch = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
      },
      //Export excel
      exportData() {
        this.exportLoading = true;
        this.$axios
            .post(
                "export/branch/import/and/export/bottles",
                {
                  startDate: this.start_date,
                  endDate: this.end_date,
                },
                { responseType: "blob" }
            )
            .then((res) => {
              setTimeout(() => {
                const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                const fileLink = document.createElement("a");
                fileLink.href = fileUrl;
                fileLink.setAttribute(
                    "download",
                    "ລາຍງານຍອດຕຸກນ້ຳສາຂາ "+this.start_date +"_"+ this.end_date + ".xlsx"
                );
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                this.exportLoading = false;
              }, 100);
            })
            .catch(() => {
              this.exportLoading = false;
              this.$store.commit("Toast_State", this.toast_error);
            });
      },
    },
    created() {
      this.fetchBranchBottle();
    },
  };