<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຕຸກນ້ຳຂອງສາຂາ</h2></v-col>
      <!--  <v-col class="al-end"> <v-btn color="primary">Export</v-btn></v-col> -->
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row>
      <v-col>
        <v-menu
          v-model="start_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date"
              label="ເລີ່ມວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start_date"
            @input="fetchBranchBottle()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="end_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date"
              label="ຫາວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end_date"
            @input="fetchBranchBottle()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="al-end">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export</v-btn
        ></v-col
      >
    </v-row>

    <v-row class="mb-1 mt-0">
      <v-col>
        <h3 class="success--text">
          ຍອດເຫຼືອທັງໝົດ:
          {{ Intl.NumberFormat().format(sumTotalBalance) }}
        </h3>
      </v-col>
      <v-col>
        <h3 class="info--text">
          ຮັບເຂົ້າທັງໝົດ:
          {{ Intl.NumberFormat().format(sumTotalImportBottles) }}
        </h3>
      </v-col>
      <v-col>
        <h3 class="error--text">
          ສົ່ງອອກທັງໝົດ:
          {{ Intl.NumberFormat().format(sumTotalExportBottles) }}
        </h3>
      </v-col>
      <v-col>
        <h3>
          ຮັບຈາກລົດ:
          {{ Intl.NumberFormat().format(sumImportBottlesFromTruck) }}
        </h3>
      </v-col>
      <v-col>
        <h3>
          ສົ່ງອອກໃຫ້ລົດ:
          {{ Intl.NumberFormat().format(sumExportBottlesToTruck) }}
        </h3>
      </v-col>
      <v-col>
        <h3>
          ສົ່ງອອກໃຫ້ລູກຄ້າ:
          {{ Intl.NumberFormat().format(sumImportBottlesToCustomer) }}
        </h3>
      </v-col>
    </v-row>

    <!-- <hr /> -->
    <v-data-table
      :headers="headers"
      :items="branchBottles"
      :items-per-page="15"
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.sumTotalBalance="{ item }">
        <v-chip color="success" @click="openModalView(item.id)">{{
          item.sumTotalBalance
        }}</v-chip>
      </template>
      <template v-slot:item.sumTotalImportBottles="{ item }">
        <v-chip color="info" @click="openModalView(item.id)">{{
          item.sumTotalImportBottles
        }}</v-chip>
      </template>
      <template v-slot:item.sumTotalExportBottles="{ item }">
        <v-chip color="error" @click="openModalViewExportBranch(item.id)">{{
          item.sumTotalExportBottles
        }}</v-chip>
      </template>

      <template v-slot:item.countImportBottleFromTruck="{ item }">
        <v-chip
          color="success"
          @click="openModalTruckTobranchBranch(item.id)"
          >{{ item.countImportBottleFromTruck }}</v-chip
        >
      </template>

      <template v-slot:item.countExportBottleToTruck="{ item }">
        <v-chip color="error" @click="openModalBranchToTruck(item.id)">{{
          item.countExportBottleToTruck
        }}</v-chip>
      </template>
      <template v-slot:item.countExportBottleToCustomer="{ item }">
        <v-chip color="error" @click="openModalBranchToCustomer(item.id)">{{
          item.countExportBottleToCustomer
        }}</v-chip>
      </template>

      <!--Action -->
    </v-data-table>

    <v-dialog
      v-model="viewModalBranch"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading" :disabled="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດ ການເຄື່ອນໄຫວຂອງສາຂາ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">ສາຂາ: {{ branchDetail.name }}</p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">ເບີໂທ: {{ branchDetail.phone }}</p>
                </v-col>
              </v-row>
              <v-data-table
                :headers="detailHeader"
                :items="branchBottleDetail"
                :disable-pagination="true"
                hide-default-footer
                class="elevation-1"
              >
                <!--
                <template v-slot:item.importFrom="{ item }">
                  {{ item.importFrom.name }}
                </template>
                <template v-slot:item.exportTo="{ item }">
                  {{ item.exportTo.name }}
                </template>
-->
              </v-data-table>
              <br />
              <template>
                <Pagination
                  v-if="pagination.last_page > 1"
                  :pagination="pagination"
                  :offset="offset"
                  @paginate="branchBotleDetail()"
                ></Pagination>
              </template>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="mr-2"
                depressed
                text
                @click="closeModalViewBranch()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      v-model="viewModalBranchExport"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading" :disabled="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດ ການເຄື່ອນໄຫວຂອງສາຂາ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">ສາຂາ: {{ branchDetail.name }}</p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">ເບີໂທ: {{ branchDetail.phone }}</p>
                </v-col>
              </v-row>
              <hr>
              <br>
                <v-row>
                  <v-col cols="6">
                    <p class="invoice">ລົດຂົນສົ່ງ: {{ exportBottle.truck }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="invoice">ລູກຄ້າ: {{ exportBottle.customer }}</p>
                  </v-col>
                </v-row>
              <hr>
              <br>
              <v-data-table
                :headers="exportdetailHeader"
                :items="branchExportBottleDetail"
                :disable-pagination="true"
                hide-default-footer
                class="elevation-1"
              >
              </v-data-table>
              <br />
              <template>
                <Pagination
                  v-if="pagination.last_page > 1"
                  :pagination="pagination"
                  :offset="offset"
                  @paginate="exportBranchBotleDetail()"
                ></Pagination>
              </template>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="mr-2"
                depressed
                text
                @click="closeModalExportBranch()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- Inbound Bottle Truck to Branch -->

    <v-dialog
      v-model="modaltruckToBranch"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ຮັບຕຸກນ້ຳເຂົ້າສາຂາ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="truckToBranchHeader"
                :items="truckList"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="openModalTruckToBranchDetail(item.id)"
                    >mdi-eye</v-icon
                  >
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModalTruckTobranch()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- ===========Bottle Truck to Branch Detail -->
    <v-dialog
      v-model="modalTruckToBranchDetail"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດຕຸກນ້ຳຈາກລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="truckToBranchHeaderDetail"
                :items="truckproducts"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                depressed
                @click="closeModalTruckToBranchDetail()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- Outbound Bottle from Branch -->

    <v-dialog
      v-model="modalBranchToTruck"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ສົ່ງຕຸກນ້ຳໃຫ້ລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="branchToTruckHeader"
                :items="branchtotruck"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="openModalBranchToTruckDetail(item.id)"
                    >mdi-eye</v-icon
                  >
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                depressed
                @click="closeModalBranchToTrucks()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- ===========Bottle outbound Branch Detail -->
    <v-dialog
      v-model="modalBranchToTruckDetail"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດຕຸກນ້ຳຈາກລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="branchToTruckHeaderDetail"
                :items="branchtotruckdetail"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                depressed
                @click="closeModalBranchToTruckDetail()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- Branch To Customer-->
    <v-dialog
      v-model="modalBranchToCustomer"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ສົ່ງຕຸກນ້ຳໃຫ້ລູກຄ້າ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="branchToCustomerHeader"
                :items="branchtocustomer"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="openModalBranchToCustomerDetail(item.id)"
                    >mdi-eye</v-icon
                  >
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                depressed
                @click="closeModalBranchToCustomer()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- ===========Branch to Customer Detail -->
    <v-dialog
      v-model="modalBranchToCustomerDetail"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດຕຸກນ້ຳຈາກລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="branchToCustomerHeaderDetail"
                :items="branchtocustomerdetail"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                depressed
                @click="closeModalBranchToCustomerDetail()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
import branchbottle from "@/mixins/reportbottle/branchbottle/branchbottle";
export default {
  mixins: [branchbottle],
  metaInfo() {
    return {
      title: `ລາຍງານຍອດຕຸກນ້ຳສາຂາ`,
    }
  },
  components: {
    Pagination,
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
