// import truckbottle  from "@/mixins/reportbottle/truckbottle"
export default {
  // mixins: [truckbottle],
    data() {
      return {
        //modal
        modalToCustomerDetail: false,
        modalToCustomer: false,

        tocustomers: [],
        tocustomerdetail:[],
        toCustomerHeaderDetail: [
            {
              text: "Barcode",
              align: "start",
              sortable: false,
              value: "barcode",
            },
            { text: "ວັນທີ", value: "import_date" },
            { text: "", value: "actions" },
          ],
        //   ======== Inbound Table
        toCustomerHeader: [
            {
              text: "ຊື່",
              align: "start",
              sortable: false,
              value: "name",
            },
            { text: "ຈຳນວນຕຸກ", value: "sumTotalBottle" },
            { text: "ວັນທີ", value: "created_at" },
            { text: "", value: "actions" },
          ],
      };
    },
    methods: {
    openModalToCustomer(id) {
        this.loading = true;
        this.truckId = id;
        this.$axios
          .post("report/driver/export/bottle/from/customer", {
            truck_id: this.truckId,
            startDate: this.start_date,
            endDate: this.end_date,
          })
          .then((res) => {
            setTimeout(() => {
              this.tocustomers = res.data.customers;
              (this.tocustomers)
              if (this.tocustomers.length > 0) {
                this.modalToCustomer = true;
              }
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
      },
      closeModalToCustomer() {
        this.tocustomers = [];
        this.modalToCustomer = false;
      },
      openModalToCustomerDetail(value) {
        this.loading = true;
        this.$axios
          .post("report/driver/export/bottle/detail/from/customer", {
            truck_id: this.truckId,
            export_to_id:value,
            startDate: this.start_date,
            endDate: this.end_date,
          })
          .then((res) => {
            setTimeout(() => {
              this.tocustomerdetail = res.data.driverProducts;
              if (this.tocustomerdetail.length > 0) {
                this.modalToCustomerDetail = true;
      
              }
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
      },
      closeModalToCustomerDetail() {
        this.tocustomerdetail = [];
        this.modalToCustomerDetail = false;
      },
    },
  };