<template>
  <div>
    <v-row>
      <v-col><h2>ສະຫຼຸບຍອດຕຸກນ້ຳຂອງລົດ</h2></v-col>
      <!--  <v-col class="al-end"> <v-btn color="primary">Export</v-btn></v-col> -->
    </v-row>
    <v-row>
      <v-col>
        <v-menu
          v-model="start_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date"
              label="ເລີ່ມວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start_date"
            @input="fetchTruckBottle()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="end_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date"
              label="ຫາວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end_date"
            @input="fetchTruckBottle()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="al-end">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export</v-btn
        ></v-col
      >
    </v-row>

    <v-row class="mb-1 mt-0">
      <v-col cols="2">
        <h4 class="success--text">
          ຍອດເຫຼືອທັງໝົດ:
          {{ Intl.NumberFormat().format(sumTotalBalance) }}
        </h4>
      </v-col>
      <v-col cols="2">
        <h4 class="info--text">
          ຮັບທັງໝົດ:
          {{ Intl.NumberFormat().format(sumTotalImportBottles) }}
        </h4>
      </v-col>
      <v-col cols="2">
        <h4 class="error--text">
          ສົ່ງອອກທັງໝົດ:
          {{ Intl.NumberFormat().format(sumTotalExportBottles) }} ຕຸກ
        </h4>
      </v-col>
      <v-col cols="2">
        <h4>
          ຮັບຈາກໂຮງງານ:
          {{ Intl.NumberFormat().format(sumTotalImportFromFactory) }}
        </h4>
      </v-col>
      <v-col cols="2">
        <h4>
          ຮັບຈາກສາຂາ:
          {{ Intl.NumberFormat().format(sumTotalImportFromBranch) }}
        </h4>
      </v-col>
      <v-col cols="2">
        <h4>
          ສົ່ງເຂົ້າສາຂາ:
          {{ Intl.NumberFormat().format(sumTotalExportToBranch) }} ຕຸກ
        </h4>
      </v-col>
      <v-col cols="2">
        <h4>
          ກັບຄືນໂຮງງານ:
          {{ Intl.NumberFormat().format(sumTotalExportToFactory) }}
        </h4>
      </v-col>
      <v-col cols="2">
        <h4>
          ສົ່ງເຂົ້າລົດ:
          {{ Intl.NumberFormat().format(sumTotalExportToTruck) }}
        </h4>
      </v-col>
      <v-col cols="2">
        <h4>
          ຮັບຈາກລົດ:
          {{ Intl.NumberFormat().format(sumTotalImportFromTruck) }}
        </h4>
      </v-col>
      <v-col cols="2">
        <h4>
          ຮັບຈາກສາງໂຮງງານ:
          {{ Intl.NumberFormat().format(sumTotalImportFromWareHouse) }}
        </h4>
      </v-col>
      <v-col cols="2">
        <h4>
          ສົ່ງເຂົ້າສາງໂຮງງານ:
          {{ Intl.NumberFormat().format(sumTotalExportToWareHouse) }}
        </h4>
      </v-col>
      <v-col cols="2">
        <h4>
          ຂາຍໃຫ້ລູກຄ້າ:
          {{ Intl.NumberFormat().format(sumTotalExportToCustomer) }}
        </h4>
      </v-col>
    </v-row>
    <!-- <hr /> -->
    <v-data-table
      :headers="headers"
      :items="truckBottle"
      :items-per-page="15"
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
    <template v-slot:item.sumTotalBalance="{ item }">
        <v-chip color="success">{{
          item.sumTotalBalance
        }}</v-chip>
      </template>
      <template v-slot:item.sumTotalImportBottles="{ item }">
        <v-chip color="info" @click="openModalViewImportTruck(item.id)">{{
          item.sumTotalImportBottles
        }}</v-chip>
      </template>
      <template v-slot:item.sumTotalExportBottles="{ item }">
        <v-chip color="error" @click="openModalExportViewTruck(item.id)">{{
          item.sumTotalExportBottles
        }}</v-chip>
      </template>
      <template v-slot:item.countImportBottleFromBranch="{ item }">
        <v-chip color="success" @click="openModalFrombranch(item.id)">{{
          item.countImportBottleFromBranch
        }}</v-chip>
      </template>
      <template v-slot:item.countExportBottleToBranch="{ item }">
        <v-chip color="success" @click="openModalInboundBranch(item.id)">{{
          item.countExportBottleToBranch
        }}</v-chip>
      </template>
      <template v-slot:item.countImportBottleFromTruck="{ item }">
        <v-chip color="success" @click="openModalFromTruck(item.id)">{{
          item.countImportBottleFromTruck
        }}</v-chip>
      </template>

      <template v-slot:item.countExportBottleToTruck="{ item }">
        <v-chip color="error" @click="openModalToTruck(item.id)">{{
          item.countExportBottleToTruck
        }}</v-chip>
      </template>

      <template v-slot:item.countExportBottleToCustomer="{ item }">
        <v-chip color="error" @click="openModalToCustomer(item.id)">{{
          item.countExportBottleToCustomer
        }}</v-chip>
      </template>
    </v-data-table>

    <v-dialog
      v-model="viewModal"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card>
          <v-card-title>
            <h4>ລາຍລະອຽດ ຮັບຕຸກນ້ຳເຂົ້າລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">ທະບຽນລົດ: {{ truckDetail.license_plate }}</p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">ເບີໂທ: {{ truckDetail.phone }}</p>
                </v-col>
              </v-row>
              <hr>
              <br>
                <v-row>
                   <v-col cols="2">
                    <p class="invoice">ໂຮງງານ: {{ importBottles.factory }}</p>
                  </v-col>
                  <v-col cols="2">
                    <p class="invoice">ສາຂາ: {{ importBottles.branch }}</p>
                  </v-col>
                  <v-col cols="2">
                    <p class="invoice">ສາງໂຮງງານ: {{ importBottles.warehouse }}</p>
                  </v-col>
                  <v-col cols="2">
                    <p class="invoice">ລົດຂົນສົ່ງ: {{ importBottles.truck }}</p>
                  </v-col>
                </v-row>
              <hr>
              <br>
              <v-data-table
                :headers="truckHeader"
                :items="bottleTruckDetail"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
              </v-data-table>
              <br />
              <template>
                <Pagination
                  v-if="pagination.last_page > 1"
                  :pagination="pagination"
                  :offset="offset"
                  @paginate="truckImportBottleDetail()"
                ></Pagination>
              </template>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModaImport()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      v-model="viewModalTruckExport"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card>
          <v-card-title>
            <h4>ລາຍລະອຽດ ຕຸກນ້ຳອອກຈາກລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">ທະບຽນລົດ: {{ truckDetail.license_plate }}</p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">ເບີໂທ: {{ truckDetail.phone }}</p>
                </v-col>
              </v-row>
              <hr>
              <br>
                <v-row>
                   <v-col cols="2">
                    <p class="invoice">ໂຮງງານ: {{ exportBottles.factory }}</p>
                  </v-col>
                  <v-col cols="2">
                    <p class="invoice">ສາຂາ: {{ exportBottles.branch }}</p>
                  </v-col>
                  <v-col cols="2">
                    <p class="invoice">ສາງໂຮງງານ: {{ exportBottles.warehouse }}</p>
                  </v-col>
                  <v-col cols="2">
                    <p class="invoice">ລົດຂົນສົ່ງ: {{ exportBottles.truck }}</p>
                  </v-col>
                  <v-col cols="2">
                    <p class="invoice">ລູກຄ້າ: {{ exportBottles.customer }}</p>
                  </v-col>
                </v-row>
              <hr>
              <br>

              <v-data-table
                :headers="exportTruckHeader"
                :items="bottleExportTruckDetail"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
              </v-data-table>
              <br />
              <template>
                <Pagination
                  v-if="pagination.last_page > 1"
                  :pagination="pagination"
                  :offset="offset"
                  @paginate="truckExportBottleDetail()"
                ></Pagination>
              </template>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModalExport()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- =============Bottle From Branch -->
    <ModalView>
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ຮັບຕຸກນ້ຳຈາກສາຂາ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="branchHeader"
                :items="frombranches"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="openModalFrombranchDetail(item.id)"
                    >mdi-eye</v-icon
                  >
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModalFromBranch()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>

    <!-- ===========Bottle From Branch Detail -->
    <ModalView2>
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດຕຸກນ້ຳຈາກສາຂາ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="branchHeaderDetail"
                :items="frombranchesdetail"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                depressed
                @click="closeModalFromBranchDetail()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView2>

    <!-- =============Bottle Inbound Branch -->

    <v-dialog
      v-model="modalInboundBranch"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ເອົາຕຸກນ້ຳເຂົ້າສາຂາ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="inboundBranchHeader"
                :items="inboundbranches"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="openModalInboundBranchDetail(item.id)"
                    >mdi-eye</v-icon
                  >
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModalInboundBranch()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- ===========Bottle Inbound Branch Detail -->
    <v-dialog
      v-model="modalInboundBranchDetail"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດຕຸກນ້ຳຈາກສາຂາ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="inboundBranchHeadeDetail"
                :items="inboundbranchesdetail"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                depressed
                @click="closeModalInboundBranchDetail()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- =============Bottle From Trucks  -->

    <v-dialog
      v-model="modalfromTruck"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ຮັບຕຸກນ້ຳຈາກລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="fromTruckHeader"
                :items="fromtrucks"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="openModalFromTruckDetail(item.id)"
                    >mdi-eye</v-icon
                  >
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModalFromTruck()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- ===========Bottle From Truck Detail -->
    <v-dialog
      v-model="modalfromTruckDetail"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດຕຸກນ້ຳຈາກລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="fromTruckHeaderDetail"
                :items="fromtruckdetail"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModalFromDetail()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- =============Bottle Outbound to Trucks  -->

    <v-dialog
      v-model="modalToTruck"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ສົ່ງຕຸກນ້ຳໃຫ້ລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="toTruckHeader"
                :items="totrucks"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="openModalToDetail(item.id)"
                    >mdi-eye</v-icon
                  >
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModalToTruck()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- ===========Bottle From Truck Detail -->
    <v-dialog
      v-model="modalToTruckDetail"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດຕຸກນ້ຳຈາກລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="toTruckHeaderDetail"
                :items="totruckdetail"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModalToTruckDetail()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- =============Bottle Outbound to Customer  -->

    <v-dialog
      v-model="modalToCustomer"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ສົ່ງຕຸກນ້ຳໃຫ້ລູກຄ້າ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="toCustomerHeader"
                :items="tocustomers"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="openModalToCustomerDetail(item.id)"
                    >mdi-eye</v-icon
                  >
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeModalToCustomer()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- ===========Bottle To Customer Detail -->
    <v-dialog
      v-model="modalToCustomerDetail"
      persistent
      width="900px"
      max-with="1200px"
      loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດຕຸກນ້ຳຈາກລົດ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="toTruckHeaderDetail"
                :items="tocustomerdetail"
                :disable-pagination="true"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                depressed
                @click="closeModalToCustomerDetail()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!--
    <modalFromBrnch
      :data="frombranches"
      :loading="loading"
      :bottleTruckDetail="bottleTruckDetail"
    />  -->
  </div>
</template> 

<script>
import Pagination from "@/plugins/pagination/pagination";
import truckbottle from "@/mixins/reportbottle/truckbottle";
// import modalFromBrnch from "@/components/reportbottle/modalFromBranch";
export default {
  mixins: [truckbottle],
  components: {
    Pagination,
    // modalFromBrnch,
  },
  metaInfo() {
    return {
      title: `ລາຍງານຍອດຕຸກນ້ຳລົດຂົນສົ່ງ`,
    }
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
